import { useCallback, useContext, useMemo } from 'react';
import { TimelinePageContext } from '..';
import styles from './InvestmentLevelsTabSection.module.scss';
import classNames from 'classnames';
import {
  ALL_LV_ID,
  DEFAULT_INVESTMENT_LEVELS,
} from 'common/utils/investment_level';
import TimelineSection from 'components/TimelineSection';
import { GetTimelinePostsResult, PostParentKey } from 'common/interfaces/api';
import useStore from 'store/timeline';

const InvestmentLevelsTabSection = () => {
  const { investmentLevels } = useContext(TimelinePageContext);
  const getNextPageParamPosts = useCallback(
    (lastPage: GetTimelinePostsResult, pages: GetTimelinePostsResult[]) => {
      const posts = lastPage?.data?.posts;
      if (posts && posts.length > 0) {
        return pages.length + 1;
      }
    },
    []
  );
  const [investmentLevelId, setInvestmentLevelId] = useStore((state) => [
    state.investmentLevelId,
    state.setInvestmentLevelId,
  ]);
  const showingInvestmentLevels = useMemo(() => {
    return [
      {
        id: ALL_LV_ID,
        name: '全て',
      },
      ...(investmentLevels?.length > 0
        ? investmentLevels
        : DEFAULT_INVESTMENT_LEVELS
      ).map((level) => {
        return {
          id: level.id,
          name: String(level.name),
        };
      }),
    ];
  }, [investmentLevels]);

  return (
    <div className={styles.investmentLevelsTabSection}>
      <div className={styles.sectionHeader}>
        <div className={styles.title}>投資レベル</div>
        <div className={styles.investmentLevelsList}>
          {showingInvestmentLevels.map((investmentLevel, index) => (
            <div
              key={`${investmentLevel.id}-${index}`}
              className={classNames(styles.investmentLevel, {
                [styles.active]:
                  investmentLevelId === String(investmentLevel.id),
              })}
              onClick={() => setInvestmentLevelId(String(investmentLevel.id))}
            >
              {investmentLevel.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.postsList}>
        <TimelineSection
          apiUrl={`/feeds/investment_levels`}
          queryKey={[
            PostParentKey.InvestmentLevelPosts,
            investmentLevelId.toString(),
          ]}
          queryParamKey="page"
          getNextPageParam={getNextPageParamPosts}
          defaultPageParam={1}
          subQuery={
            investmentLevelId !== ALL_LV_ID && {
              key: 'level_id',
              value: investmentLevelId.toString(),
            }
          }
        />
      </div>
    </div>
  );
};

export default InvestmentLevelsTabSection;
