import { cloneElement, Dispatch, MouseEventHandler, ReactElement } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';

import styles from './PopperWrapper.module.scss';

export type Props = {
  buttonEl: ReactElement;
  contentEl: ReactElement;
  anchorEl: HTMLElement;
  setAnchorEl: Dispatch<HTMLElement>;
  click?: boolean;
  hover?: boolean;
  showArrow?: boolean;
  disablePortal?: boolean;
  verticalOffset?: number;
  horizontalOffset?: number;
  id?: string;
};
export default function PopperWrapper({
  buttonEl,
  contentEl,
  anchorEl,
  setAnchorEl,
  click = true,
  hover = false,
  showArrow = true,
  disablePortal = false,
  verticalOffset = 10,
  horizontalOffset = 20,
  id,
}: Props): JSX.Element {
  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleMouseEnter: MouseEventHandler<HTMLElement> = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMouseLeave: MouseEventHandler = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const renderBtn = cloneElement(buttonEl, {
    onClick: click ? handleClick : undefined,
    onMouseEnter: hover ? handleMouseEnter : undefined,
    'data-is-open': open,
  });

  const arrowOffset = 15;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.popperWrapper}>
        {renderBtn}
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          modifiers={{
            offset: {
              enabled: true,
              offset: `-${horizontalOffset}, ${verticalOffset}`,
            },
          }}
          disablePortal={disablePortal}
        >
          <div
            className={styles.container}
            onMouseLeave={hover ? handleMouseLeave : undefined}
          >
            {contentEl}
            {showArrow && (
              <div
                className={styles.arrow}
                style={{ left: `calc(50% + ${arrowOffset}px)` }}
              ></div>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
