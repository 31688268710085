import axios from 'axios';
import React, { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import useGlobalStore from 'store/global';

import {
  Post,
  GetTrendingTopicResult,
  PostParentKey,
} from 'common/interfaces/api';
import InfiniteScroll from 'components/common/InfiniteScroll';
import PostItem from 'components/Post';
import Icon from 'components/common/Icon';

import styles from './TopicSection.module.scss';
import classNames from 'classnames';
import { PostsLoader } from 'components/common/PreloadContentLoader';
import useStore from 'store/timeline';
import { useMountedDelay } from 'common/utils/hooks/useDidMount';
import { useRouter } from 'next/router';

type Props = {
  topic: string;
  className?: string;
  sortType: 'latest' | 'recommend';
  setSortType: (sortType: 'latest' | 'recommend') => void;
};

export default function PostTopicSection({
  topic,
  className,
  sortType,
  setSortType,
}: Props): JSX.Element {
  const shouldRefetchPosts = useStore((state) => state.shouldRefetchPosts);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const mounted = useMountedDelay();
  const router = useRouter();
  const order = router.query.order as string;
  useEffect(() => {
    setSortType(order === 'latest' ? 'latest' : 'recommend');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);
  const {
    isLoading,
    data,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    refetch,
  } = useInfiniteQuery(
    ['fetchPostTopicData', topic, sortType],
    async ({ pageParam = 1 }) => {
      if (!isAuthInit || !topic || !mounted) return Promise.reject();
      const url = `/feeds/domain_posts?domain_name=${topic}&page=${
        pageParam as number
      }&sort=${sortType}`;
      return (await axios.get(url)).data as GetTrendingTopicResult;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const posts = lastPage?.data?.posts;
        if (posts && posts.length > 0) {
          return pages.length + 1;
        }
      },
      enabled: isAuthInit && !!topic && mounted,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  const pages = data?.pages;
  const posts: Post[] = useMemo(
    () =>
      pages
        ? pages.reduce<Post[]>((curr, page) => {
            const posts = page.data?.posts;
            return curr.concat(posts);
          }, [])
        : [],
    [pages]
  );

  const postsRender = posts.map((post, index) => (
    <div key={`${post?.id}-${index}`} className={styles.post}>
      <PostItem post={post} hasFollowBtn parent={PostParentKey.TopicPosts} />
    </div>
  ));

  let noPostPlaceholder;
  if (!isLoading && posts.length === 0) {
    noPostPlaceholder = (
      <div className={styles.noPost}>
        <Icon name="empty" width={96} height={96} />
        <div>該当する投稿がありません</div>
      </div>
    );
  }

  useEffect(() => {
    if (shouldRefetchPosts) {
      void refetch();
    }
  }, [shouldRefetchPosts, refetch]);

  return (
    <div className={classNames(styles.container, className ?? '')}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.keyword}>{topic}の最新ニュース</h1>
        <div className={styles.sortSection}>
          <button
            className={classNames(styles.linkBtn, {
              [styles.active]: sortType === 'recommend',
            })}
            style={isLoading ? { pointerEvents: 'none' } : {}}
            onClick={() => {
              setSortType('recommend');
              const isPostTopic = router.pathname.startsWith('/post_topics');
              if (!isPostTopic) return;
              void router.push(
                {
                  pathname: `/post_topics/${topic}`,
                },
                `/post_topics/${topic}`,
                { shallow: true }
              );
            }}
          >
            おすすめ
          </button>

          <button
            className={classNames(styles.linkBtn, {
              [styles.active]: sortType === 'latest',
            })}
            style={isLoading ? { pointerEvents: 'none' } : {}}
            onClick={() => {
              setSortType('latest');
              const isPostTopic = router.pathname.startsWith('/post_topics');
              if (!isPostTopic) return;
              void router.push(
                {
                  pathname: `/post_topics/${topic}`,
                  query: {
                    order: 'latest',
                  },
                },
                `/post_topics/${topic}?order=latest`,
                { shallow: true }
              );
            }}
          >
            新着
          </button>
        </div>
      </div>
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        isLoading={isFetchingNextPage}
      >
        {isFetched ? postsRender : <PostsLoader />}
      </InfiniteScroll>
      {noPostPlaceholder}
    </div>
  );
}
