import styles from './HomeRecommendCreatorsList.module.scss';
import { useContext, useMemo, useState } from 'react';
import { MainTemplateContext } from 'components/templates/MainTemplate';
import LinkButton from 'components/common/LinkButton';
import { User } from 'common/interfaces/api';
import useGlobalStore from 'store/global';
import { handleFollowAction } from 'service/user';
import BadgeIcon from 'components/common/BadgeIcon';
import RoundIcon from 'components/common/RoundIcon';
import Link from 'next/link';

const HomeRecommendCreatorsList = () => {
  const { spotlightUsers, isFetchedSpotlightUsers } =
    useContext(MainTemplateContext);
  const [isShowingLimit, setIsShowingLimit] = useState(true);
  const users = useMemo(() => {
    return [...(spotlightUsers || [])].reverse().slice(0, 10);
  }, [spotlightUsers]);

  if (!isFetchedSpotlightUsers || !users || users?.length === 0) return null;
  const showingLimitButton = users && users?.length > 5;
  return (
    <div className={styles.homeRecommendCreatorsList}>
      <div className={styles.title}>おすすめクリエイター</div>
      <div className={styles.creatorsList}>
        {(users || []).map((user, index) => {
          return (
            <CreatorItem
              user={user}
              key={`${user.id}-${index}`}
              isHiding={isShowingLimit && index > 4}
            />
          );
        })}
        {showingLimitButton && (
          <div className={styles.readMoreBtn}>
            <LinkButton
              text={isShowingLimit ? 'もっと見る' : '一部表示'}
              nonUnderline
              fontSize={14}
              onClick={() => {
                setIsShowingLimit((prev) => !prev);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const CreatorItem = ({ user, isHiding }: { user: User; isHiding: boolean }) => {
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );

  // Follow button
  // use local state to make sure user's action is immediate
  const [isFollowed, setIsFollowed] = useState(user.is_followed);
  const handleFollowClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (!user) return;
    if (currentAuth) {
      try {
        const action = isFollowed ? 'unfollow' : 'follow';
        if (isFollowed) {
          setIsFollowed(false);
        } else {
          setIsFollowed(true);
        }
        await handleFollowAction(user.id, action);
      } catch (e) {
        setSnackbarMessage({
          type: 'error',
          text: 'エラーが発生しました。',
        });
      }
    } else {
      showLogin({});
    }
  };
  return (
    <Link href={`/${user.username}`}>
      <a
        className={styles.creatorItem}
        style={isHiding ? { display: 'none' } : {}}
      >
        <div className={styles.creatorInfoWrapper}>
          <div className={styles.creatorAvatar}>
            <RoundIcon
              user={user}
              width={48}
              height={48}
              hasRing
              enableClick={false}
              emotion={null}
              showLiveFlag={false}
            />
          </div>
          <div className={styles.creatorInfo}>
            <div className={styles.userNameWrapper}>
              <div className={styles.userName} title={user.name}>
                {user.name}
              </div>
              <div className={styles.badge}>
                <BadgeIcon badge={user.badge} width={36} height={36} />
              </div>
            </div>
            {user.bio && <div className={styles.userBio}>{user.bio}</div>}
          </div>
        </div>
        {!isFollowed && (
          <button
            className={styles.followBtn}
            onClick={(e) => handleFollowClick(e)}
          >
            フォロー
          </button>
        )}
      </a>
    </Link>
  );
};

export default HomeRecommendCreatorsList;
