import axios from 'axios';
import apiConfig from 'config/api';
import { SurveyType } from './survey';
import { TopicType } from './user';
import { Post, SymbolChart } from 'common/interfaces/api';
import { InvestmentLevel } from './investment_level';
export const getSystemSettings = async (abTestKey?: string) => {
  const abTestParam = abTestKey ? `?ab_keys=${abTestKey}` : '';
  return (
    await axios({
      method: 'GET',
      baseURL: apiConfig.BACKEND_BASE_URL.replace(`/v1`, ''),
      url: `/system/versions${abTestParam}`,
    })
  )?.data as {
    data: {
      versions: { [key: string]: string };
      features: { [key: string]: string };
      surveys: { [key: string]: SurveyType };
      ab_test_groups?: { [key: string]: string };
    };
  };
};

export type LiveUserType = {
  post_id: number;
  user_id: number;
  username: string;
  profile_image: string;
  published_at: string;
};

export const getInitTimelineData = async () => {
  return (await axios.get('/feeds/home-timeline', { params: { page: 1 } }))
    ?.data as {
    data: {
      sections: {
        trending_posts: Post[];
        domains: TopicType[];
        symbol: SymbolChart;
        on_stream_users: LiveUserType[];
        feed_investment_levels: InvestmentLevel[];
      }[];
    };
  };
};
