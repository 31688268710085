import { useContext, useEffect, useRef } from 'react';
import { TimelinePageContext } from '..';
import styles from './PostTopicsTabSection.module.scss';
import { ForwardIcon } from 'components/common/Icon';
import classNames from 'classnames';
import PostTopicSection from 'components/PostTopicSection';
import useStore, { INVESTMENT_TAB_NAME } from 'store/timeline';
import { useMediaQuery } from 'common/utils';
import CreatePostBox from 'components/CreatePostBox';
import InvestmentLevelsTabSection from '../InvestmentLevelsTabSection';

const PostTopicsTabSection = () => {
  const { postTopics } = useContext(TimelinePageContext);
  const [currentPostTopicName, setCurrentPostTopicName] = useStore((state) => [
    state.currentPostTopicName,
    state.setCurrentPostTopicName,
  ]);
  const isInvestmentLevel = currentPostTopicName === INVESTMENT_TAB_NAME;
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [sortType, setSortType] = useStore((state) => [
    state.sortType,
    state.setSortType,
  ]);
  useEffect(() => {
    if (!currentPostTopicName && postTopics?.[0]?.name) {
      setCurrentPostTopicName(postTopics?.[0]?.name);
    }
  }, [currentPostTopicName, postTopics, setCurrentPostTopicName]);
  const topicsRef = useRef<HTMLDivElement>();

  const scrollShowMore = (offset: number) => {
    if (topicsRef.current) {
      topicsRef.current.scrollLeft += offset;
    }
  };

  if (!currentPostTopicName) {
    return null;
  }
  return (
    <div className={styles.postTopicsTabSection}>
      <div className={styles.topicsListWrapper}>
        <div className={styles.topicsList} ref={topicsRef}>
          {postTopics.length > 0 && (
            <div
              className={styles.backBtn}
              onClick={() => {
                scrollShowMore(-80);
              }}
            >
              <ForwardIcon width={24} height={24} color="#727272" />
            </div>
          )}
          {postTopics.length > 0 && (
            <div
              className={styles.nextBtn}
              onClick={() => {
                scrollShowMore(80);
              }}
            >
              <ForwardIcon width={24} height={24} color="#727272" />
            </div>
          )}
          {postTopics.map((topic, index) => (
            <div
              key={`${topic.id}-${index}`}
              className={classNames(styles.topic, {
                [styles.active]: currentPostTopicName === topic.name,
              })}
              onClick={() => {
                setCurrentPostTopicName(topic.name);
                setSortType('recommend');
              }}
            >
              {topic.name}
            </div>
          ))}
        </div>
      </div>
      {!isMobile && (
        <div className={styles.createPost}>
          <CreatePostBox />
        </div>
      )}
      {isInvestmentLevel ? (
        <InvestmentLevelsTabSection />
      ) : (
        <PostTopicSection
          topic={currentPostTopicName}
          className={styles.postsTopicList}
          sortType={sortType}
          setSortType={setSortType}
        />
      )}
    </div>
  );
};

export default PostTopicsTabSection;
