import cn from 'classnames';
import axios from 'axios';

import { Emotion } from 'common/interfaces/api';
import useStore from 'store/timeline';
import Icon from 'components/common/Icon';

import styles from './EmotionSelector.module.scss';

export default function EmotionSelector(): JSX.Element {
  const currentUser = useStore((state) => state.currentUser);
  const refetchProfile = useStore((state) => state.refetchProfile);

  const handleUpdateEmotion = async (emotion: string) => {
    await axios.post(`/profiles/emotions`, { emotion });
    refetchProfile();
  };

  const handleDeleteEmotion = async () => {
    await axios.delete(`/profiles/emotions`);
    refetchProfile();
  };

  return (
    <div className={styles.selectEmotionMenu}>
      <div className={styles.emotions}>
        {Object.values(Emotion).map((emotion) => {
          const isActive = emotion === currentUser?.emotion;
          return (
            <div
              key={emotion}
              className={cn(styles.emotion, { [styles.active]: isActive })}
              onClick={() => handleUpdateEmotion(emotion)}
            >
              <Icon name={`emotion-sel-${emotion}`} width={24} height={24} />
              <div className={styles.highlight} />
            </div>
          );
        })}
      </div>
      <div
        className={styles.delEmotionBtn}
        onClick={() => handleDeleteEmotion()}
      >
        ムード絵文字を消す
      </div>
    </div>
  );
}
